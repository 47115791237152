import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGO_WARNING_18, STORAGE } from 'utils/constant';
import Storage from 'utils/storage';
import { HeaderListIdolV2, ImgCustom } from '../../components';
import './index.scss';
import ConfirmNoAge from './confirmNo';
import { newPathUser } from 'constants/layout/constant';

const VerifiAge = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showConfirm, setShowConfirm] = useState(true);
  useEffect(() => {
    if (location?.search) {
      const checkVerify = location.search?.split('verify_email=true');
      if (checkVerify) {
        Storage.set(STORAGE.VERIFY_AGE, true);
        navigate(location?.pathname + location?.search);
      }
    }
  }, []);

  return (
    <>
      {showConfirm ? (
        <div className='block-div-signin'>
          <HeaderListIdolV2 />
          <div className='fansite-verify-age 466'>
            <div className='box-verify-age'>
              <div className='bg-white'>
                <div className='title'>{t('CheckAge.title')}</div>
                <div className='header-popup'>
                  <ImgCustom src={LOGO_WARNING_18} alt='logo' className='logo-cocofans' />
                </div>
                <div className='sub-title title'>{t('CheckAge.areYou18')}</div>
                <div className='text display-linebreak'>
                  {t('CheckAge.text1')}
                </div>
                <div className='btn-wrapper'>
                  <div
                    className='btn btn-yes'
                    onClick={() => {
                      Storage.set(STORAGE.VERIFY_AGE, true);
                      let requestToken = window.localStorage.getItem('USER_REQUEST_TOKEN');
                      navigate(
                        `${
                          ((location?.pathname === `${newPathUser}authentication`) && !requestToken) ? '/sign-in' :
                          location?.pathname?.includes('lives')
                            ? location?.pathname
                            : location?.pathname === '/'
                            ? ''
                            // : 'sign-in?' + location?.pathname
                            : location?.pathname
                        } `,
                        {
                          state: { link_share: location?.pathname || location?.search },
                        }
                      );
                    }}>
                    {t('Common.buttonYes')}
                  </div>
                  <div
                    className='btn btn-no cancel'
                    onClick={() => {
                      Storage.set(STORAGE.VERIFY_AGE, false);
                      window.location.href = 'https://www.google.com';
                    }}>
                    {t('Common.buttonNo')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ConfirmNoAge handleBack={() => setShowConfirm(true)}/>
      )}
    </>
  );
};

export default VerifiAge;
